import React from "react";
import * as  styles from "./home.module.css";
import app from "../../images/homethird.png";

export default function Homethird() {
  return (
    <div className={styles.zectors_main_div}>
      <div className="container-el container-lg d-flex flex-column item-center">
        <div className="d-flex flex-wrap-md flex-column-md justify-center">
          <div
            className={`${styles.zector_img} d-flex justify-center  item-center flex-33 align-self-center pl-30-lg pr-30-lg pr-50-el pl-30 pr-15`}
          >
            <div className={styles.img_div}>
              <img className="w-100 " src={app} alt="" />
            </div>
          </div>
          <div className=" flex-1 pl-15 pl-50-el pl-30-lg pr-30 bs-bb" style={{fontFamily: `Montserrat, sans-serif`}}>
            <h3 className="title-el title text-center mb-30 " style={{fontFamily: `Montserrat, sans-serif`}}>
              Step 3: Development{" "}
            </h3>
            <p className="desc-el desc" style={{fontFamily: `Montserrat, sans-serif`}}>
              Development is kicked off with a scheduled call with you to
              restate our objectives and confirm how we will communicate
              throughout the rest of this project. We believe in consistent
              communication for all of our projects. We use a project management
              tool so we can share weekly written status updates, track
              individual engineer tasks, resolve issues, and provide access to
              shared documents.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
