import React from "react";
import * as  styles from "./home.module.css";
import { Link } from "gatsby";


export default function HomeCover() {
  return (
    <div
      className={`${styles.Home_cover} cover d-flex justify-center item-center`}
    >
      <div className="home_cover_text pl-30 pr-30 pr-15-sm pl-15-sm">
        <h3 className="cover_text mb-60 text-center" style={{fontFamily: `Montserrat, sans-serif`}}>
          Let's build something <span className="text-zinc"> together</span>{" "}
        </h3>
        <Link to="/contact" className="btn bs-bb no-underline text-white m-auto" style={{fontFamily: `Montserrat, sans-serif`}}>
          Tell us about your project
        </Link>
      </div>
    </div>
  );
}
