import React from "react";
import * as styles from "./home.module.css";
import client1 from "../../images/client1.png";
import clinet2 from "../../images/client2.png";
import client3 from "../../images/client3.png";

export default function ClientSection() {
  return (
    <div className={styles.client_main}>
      <div className="main-container container-lg bs-bb" style={{fontFamily: `Montserrat, sans-serif`}}>
        <h2 className={`${styles.title} text-center mb-110`} style={{fontFamily: `Montserrat, sans-serif`}}> Testimonials </h2>
        <div
          className={`${styles.zector_card_box} d-flex justify-center flex-wrap`}
        >
          <div
            className={`${styles.zector_client_card} home-card flex-32 ps-relative bs-bb d-flex flex-column justify-center item-center`}
          >
            <div className={`${styles.client_avatar} ps-absolute`}>
              <img alt="#" src={client1} />
              <h3 className={`${styles.client_title} text-center`} style={{fontFamily: `Montserrat, sans-serif`}}>Alauna J</h3>
            </div>
            <div className={`${styles.card_content} m-auto text-center`}>
              <p className={`${styles.card_desc} `} style={{fontFamily: `Montserrat, sans-serif`}}>
                “Zector team is very hands on and attentive to our business
                needs. It has been a pleasure working with them.”
              </p>
            </div>
          </div>
          <div
            className={`${styles.zector_client_card} home-card flex-32 ps-relative bs-bb d-flex flex-column justify-center item-center`}
          >
            <div className={`${styles.client_avatar} ps-absolute`}>
              <img alt="#" src={clinet2} />
              <h3 className={`${styles.client_title} text-center`} style={{fontFamily: `Montserrat, sans-serif`}}>Rita D</h3>
            </div>
            <div className={`${styles.card_content} m-auto text-center`}>
              <p className={`${styles.card_desc} `} style={{fontFamily: `Montserrat, sans-serif`}}>
                “The Zector team diligently places meeting the needs of customer
                at the center of their business agenda. Their live chat options,
                video calls and curated agendas are a key examples for
                showcasing their rapid and precise results mindset which their
                company embodies. Team Zector is the optimal choice for meeting
                my business needs!”
              </p>
            </div>
          </div>
          <div
            className={`${styles.zector_client_card} home-card flex-32 ps-relative bs-bb d-flex flex-column justify-center item-center`}
          >
            <div className={`${styles.client_avatar} ps-absolute`}>
              <img alt="#" src={client3} />
              <h3 className={`${styles.client_title} text-center`} style={{fontFamily: `Montserrat, sans-serif`}}>David O</h3>
            </div>
            <div className={`${styles.card_content} m-auto text-center`}>
              <p className={`${styles.card_desc} `} style={{fontFamily: `Montserrat, sans-serif`}}>
                “Zector is our go to for all our critical projects. Pleasure to
                work with. They always bring the best solution and produce a
                clean and bug free final product.”
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
