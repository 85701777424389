import React from "react";
import * as styles from "./home.module.css";
import Chess from "../../images/home_chess.png";

import {StaticImage} from "gatsby-plugin-image";
export default function HomeFirst() {
  return (
    <div className={styles.zectors_main_div}>
      <div className="container-el container-lg d-flex flex-column item-center">
        <h2
          className={`${styles.title} mb-135`}
          style={{fontFamily: `Montserrat, sans-serif`}}
        >
          Zector's <span className="text-zinc">Process</span>
        </h2>
        <div className="d-flex flex-wrap-md flex-column-md justify-center">
          <div
            className={`${styles.zector_img} d-flex justify-center  item-center flex-33 align-self-center pl-30-lg pr-30-lg pr-50-el pl-30 pr-15`}
          >
            <div className={styles.img_div}>
              <img className="w-100 " src={Chess} alt="" />
            
            </div>
          </div>
          <div className=" flex-1 pl-15 pl-50-el pl-30-lg pr-30 bs-bb">
            <h3
              className="title-el title text-center mb-30 "
              style={{fontFamily: `Montserrat, sans-serif`}}
            >
              Step 1: Strategy
            </h3>
            <p
              className="desc-el desc"
              style={{fontFamily: `Montserrat, sans-serif`}}
            >
              First, we start understanding your core business objectives and
              create a roadmap to outline your goals, timeline, challenges, plan
              for dealing with the challenges, and actionable steps to execute
              the plan. Your stakeholders will meet with our project team for a
              research engagement tailored to fit your project. After the
              meeting we will type up a report with major takeaways of the
              meeting, and weigh in with additional insights and observations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
