import React from "react";
import * as styles from "./home.module.css";
import card1 from "../../images/card1.png";
import card2 from "../../images/card2.png";
import card3 from "../../images/card3.png";
import card4 from "../../images/card4.png";
import card5 from "../../images/card5.png";
import card6 from "../../images/card6.png";
import { ArrowRightOutlined } from "@ant-design/icons";
export default function ZectorCards() {
  return (
    <div className={styles.zector_cards}>
      <div className="main-container container-lg bs-bb">
        <div className={`${styles.zector_card_heading}  m-auto text-center`}>
          <h2 className={`${styles.title} mb-10`} style={{fontFamily: `Montserrat, sans-serif`}}>
            Why <span className="text-zinc">Zector?</span>
          </h2>
          <p className={`${styles.zector_title_secondry} `} style={{fontFamily: `Montserrat, sans-serif`}}>
            We take care all of your technology needs
          </p>
        </div>
        <div
          className={`${styles.zector_card_box} d-flex justify-center flex-wrap`}
        >
          <div
            className={`${styles.zector_card} home-card bs-bb d-flex justify-center flex-column item-center`}
            style={{fontFamily: `Montserrat, sans-serif`}}
          >
            <h3 className={`${styles.card_title} text-center`}>
              Web Development
            </h3>
            <div className={`${styles.card_img}`}>
              <img className="w-100" alt="dummy" src={card1} />
            </div>
            <div className={styles.card_content}>
              <p className={`${styles.card_desc} mb-30`}>
                From user interface development to API development, our
                engineers can write modular, well-documented, responsive code
                with a diverse stack of technologies to fit each customer's needs. We make sure to get it right the first time. 
              </p>
            </div>
          </div>
          <div
            className={`${styles.zector_card} home-card bs-bb d-flex justify-center flex-column item-center`}
            style={{fontFamily: `Montserrat, sans-serif`}}
          >
            <h3 className={`${styles.card_title} text-center`}>
              Mobile App Development
            </h3>
            <div className={`${styles.card_img}`}>
              <img className="w-100" alt="dummy" src={card2} />
            </div>
            <div className={styles.card_content}>
              <p className={`${styles.card_desc} mb-30`}>
                Our mobile development team can help you put your company in
                your customer's pocket by increasing brand engagement with an intuitive, creative and interactive cross-platform application. Cross platform development allows us to write your codebase once and run everywhere. 
              </p>
            </div>
          </div>
          <div
            className={`${styles.zector_card} home-card bs-bb d-flex justify-center flex-column item-center`}
            style={{fontFamily: `Montserrat, sans-serif`}}
          >
            <h3 className={`${styles.card_title} text-center`}>UI/UX Design</h3>
            <div className={`${styles.card_img}`}>
              <img className="w-100" alt="dummy" src={card3} />
            </div>
            <div className={styles.card_content}>
              <p className={`${styles.card_desc} mb-30`}>
                Great user experiences are important to us for every software
                that we create. We take the time to understand your users pain
                points to ensure your users get the best experience.
              </p>
            </div>
          </div>
          <div
            className={`${styles.zector_card} home-card bs-bb d-flex justify-center flex-column item-center`}
            style={{fontFamily: `Montserrat, sans-serif`}}
          >
            <h3 className={`${styles.card_title} text-center`}>Web Scraping</h3>
            <div className={`${styles.card_img}`}>
              <img className="w-100" alt="dummy" src={card4} />
            </div>
            <div className={styles.card_content}>
              <p className={`${styles.card_desc} mb-30`}>
                The internet is full of data waiting to be extracted,
                transformed, and analyzed by businesses. Our engineers create
                custom web scraping solutions to quickly gather the data that you need and provide it in a elegant structure.
              </p>
            </div>
          </div>
          <div
            className={`${styles.zector_card} home-card bs-bb d-flex justify-center flex-column item-center`}
            style={{fontFamily: `Montserrat, sans-serif`}}
          >
            <h3 className={`${styles.card_title} text-center`}>
              Media Services
            </h3>
            <div className={`${styles.card_img}`}>
              <img className="w-100" alt="dummy" src={card5} />
            </div>
            <div className={styles.card_content}>
              <p className={`${styles.card_desc} mb-30`}>
                Our engineers develop live stream and video on demand solutions
                to give you more control over your content and to increase
                customer engagement. We also provide payment integration as a core feature for subscription media services. 
              </p>
            </div>
          </div>
          <div
            className={`${styles.zector_card} home-card bs-bb d-flex justify-center flex-column item-center`}
            style={{fontFamily: `Montserrat, sans-serif`}}
          >
            <h3 className={`${styles.card_title} text-center`}>QA & Support</h3>
            <div className={`${styles.card_img}`}>
              <img className="w-100" alt="dummy" src={card6} />
            </div>
            <div className={styles.card_content}>
              <p className={`${styles.card_desc} mb-30`}>
                From functionality to security, our team has you covered. We
                pride ourselves on our communication and provide weekly updates
                with a fair turn around time in customer communication. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
