// extracted by mini-css-extract-plugin
export const zectors_main_div = "home-module--zectors_main_div--3LLHt";
export const img_div = "home-module--img_div--CYIw0";
export const card_secondry = "home-module--card_secondry--1QYzz";
export const zector_cards = "home-module--zector_cards--2eVl8";
export const zector_card_heading = "home-module--zector_card_heading--3bEH5";
export const zector_title_secondry = "home-module--zector_title_secondry--2rK8_";
export const zector_card = "home-module--zector_card--34pqV";
export const card_content = "home-module--card_content--1B6jW";
export const zector_client_card = "home-module--zector_client_card--y4ETm";
export const client_avatar = "home-module--client_avatar--1gtPw";
export const card_title = "home-module--card_title--84374";
export const card_img = "home-module--card_img--18TIC";
export const card_desc = "home-module--card_desc--3MpWE";
export const card_btn = "home-module--card_btn--3kj6N";
export const client_main = "home-module--client_main--31qMp";
export const client_title = "home-module--client_title--1gHDn";
export const title = "home-module--title--3_7IY";
export const zector_img = "home-module--zector_img--3L7rX";