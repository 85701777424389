import * as React from "react";
import HeaderCompo from "../components/header/Header";
import HomeFirst from "../components/home/homeFirst";
import HomeSecond from "../components/home/homeSecond";
import Homethird from "../components/home/homethird";
import HomeFrth from "../components/home/homefrth";
import HomeFifth from "../components/home/home5th";
import HomeCover from "../components/home/homeCover";
import ZectorCards from "../components/home/zectorCards";
import ClientSection from "../components/home/home_client";
import HomeCoverScnd from "../components/home/homeCoverScnd";
import { Layout } from "../components/Layout";
import indexbg from "../images/background.png";
import SEO from "../components/seo";

// markup
function IndexPage() {
  return (
    <>
      <Layout style={{fontFamily: `Montserrat, sans-serif`}}>
        <SEO
          title="Software Development Company | Zector"
          image="https://zectorimages.s3.amazonaws.com/zector-banner.jpg"
          url="https://www.zector.io"
          google="Jdwwo0F9WIFHqUpW4W8bjzRRnjYE3_kYPOZZzZiavM8"
          ahrefs="b4aa86342d804e163d5e26301201a52ebc46a277fb1601c81400fd7f7adad2e9"
          description="Zector is a software development company that helps startup founders design web and mobile solutions in the cloud to attract investors."
        />
        <HeaderCompo
          headerTitle="We help startup founders roadmap their product idea to attract investors"
          headerBtn="Tell us about your project"
          headerbg={indexbg}
        />
        <HomeFirst />
        <HomeSecond />
        <Homethird />
        <HomeFrth />
        <HomeFifth />
        <HomeCover />
        <ZectorCards />
        <HomeCoverScnd />
        <ClientSection />
      </Layout>
    </>
  );
}

export default IndexPage;
